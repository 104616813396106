import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import styled from 'styled-components'
import { default as Link } from 'components/pageTransitionLink'
import { graphql } from 'gatsby'

const Section = styled.section`
  max-width: 1128px;
  margin: var(--xl) auto;
  padding: 0 var(--md);

  h1 {
    font-size: var(--titleLevel1);
    margin-bottom: var(--lg);
  }
  h4 {
    font-size: var(--copyNormal);
    margin-bottom: var(--sm);
  }
  ul {
    margin-bottom: var(--lg);
  }
  li {
    margin-bottom: var(--sm);
  }
  a {
    color: var(--tertiary);
    text-decoration: underline;
    font-size: var(--copyNormal);
    font-family: var(--headings);
    font-weight: 400;
    font-style: normal;

    :hover {
      // text-decoration: none;
    }
  }
`

const SitemapPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>Sitemap</title>
    </Helmet>
    <Section>
      <h1>Sitemap</h1>

      <h4>Homepage</h4>
      <ul>
        <li>
          <Link to="/">https://www.pcwsolutions.co.uk</Link>
        </li>
      </ul>

      <h4>Who We Are</h4>
      <ul>
        <li>
          <Link to="/managed-it-support-company">https://www.pcwsolutions.co.uk/managed-it-support-company</Link>
        </li>
      </ul>

      <h4>IT Services</h4>
      <ul>
        <li>
          <Link to="/business-it-services">https://www.pcwsolutions.co.uk/business-it-services</Link>
        </li>
      </ul>

      <h4>IT Security</h4>
      <ul>
        <li>
          <Link to="/business-it-security">https://www.pcwsolutions.co.uk/business-it-security</Link>
        </li>
        {data.allSecurityPage.nodes.map((item, i) => (
          <li key={i}>
            <Link to={item.path}>{'https://www.pcwsolutions.co.uk' + item.path}</Link>
          </li>
        ))}
      </ul>

      <h4>VoIP</h4>
      <ul>
        <li>
          <Link to="/voip-business-calls">https://www.pcwsolutions.co.uk/voip-business-calls</Link>
        </li>
      </ul>

      <h4>Cloud</h4>
      <ul>
        <li>
          <Link to="/business-cloud-services">https://www.pcwsolutions.co.uk/business-cloud-services</Link>
        </li>
      </ul>

      <h4>News</h4>
      <ul>
        <li>
          <a href="https://news.pcwsolutions.co.uk/" rel="noopener noreferrer">
            https://news.pcwsolutions.co.uk
          </a>
        </li>
      </ul>

      <h4>Contact</h4>
      <ul>
        <li>
          <Link to="/business-it-contact">https://www.pcwsolutions.co.uk/business-it-contact</Link>
        </li>
      </ul>

      <h4>Client Login</h4>
      <ul>
        <li>
          <a href="https://help.pcwsolutions.co.uk/" target="_blank" rel="noopener noreferrer">
            https://help.pcwsolutions.co.uk
          </a>
        </li>
      </ul>

      <h4>Support Locations</h4>
      <ul>
        {data.allLocationPage.nodes.map((item, i) => (
          <li key={i}>
            <Link to={item.path}>{'https://www.pcwsolutions.co.uk' + item.path}</Link>
          </li>
        ))}
      </ul>

      <h4>Pages</h4>
      <ul>
        <li>
          <Link to="/restaurants-it-support">https://www.pcwsolutions.co.uk/restaurants-it-support/</Link>
        </li>
        <li>
          <Link to="/schools-it-support">https://www.pcwsolutions.co.uk/schools-it-support/</Link>
        </li>
        <li>
          <Link to="/dental-practices-it-support">https://www.pcwsolutions.co.uk/dental-practices-it-support/</Link>
        </li>
        <li>
          <Link to="/estate-agents-it-support">https://www.pcwsolutions.co.uk/estate-agents-it-support/</Link>
        </li>
      </ul>

    </Section>
  </Layout>
)

SitemapPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default SitemapPage

export const query = graphql`
  query SitemapPageQuery {
    allLocationPage: allSitePage(filter: { path: { glob: "*/local-it-support-area/*" } }) {
      nodes {
        path
      }
    }
    allSecurityPage: allSitePage(filter: { path: { glob: "*/business-it-security/*" } }) {
      nodes {
        path
      }
    }
  }
`
